import _is from "./is";
import _keys from "./keys";
import _copy from "./copy";
var exports = {};
var is = _is,
    keys = _keys,
    copy = _copy;

exports = function overwrite(to) {
  return function (from) {
    keys(from).map(copy(from, to));
    return to;
  };
};

export default exports;